import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRowNumber } from 'src/helpers/utils';
import { RootState } from 'src/types/store-types';
import '../styles.scss';
import HomePageGamesSlider from './HomePageGamesSlider';

type Props = {
  settings: {
    prioiry: number;
    data: any[];
  };
};

type ITagsDataProps = {
  category: string;
  limit: number;
  tagName: string;
  trTagName: string;
  template: string;
};

const HomePageGamesSliders: FC<Props> = ({ settings }) => {
  const { data } = settings;
  const { pageTagsPreviews } = useSelector((state: RootState) => state.configs);
  const [tagsData, setTagsData] = useState<ITagsDataProps[]>([]);
  const lng = localStorage.getItem('selectedLanguage');

  useEffect(() => {
    if (pageTagsPreviews && lng) {
      const newTagsData = pageTagsPreviews.map((item: Record<string, string>) => ({
        category: item.category,
        limit: 24,
        tagName: item.tag,
        trTagName: item.translations[lng],
        template: 'template_' + getRowNumber(item.viewType),
        priority: item.priority,
      }));
      setTagsData(newTagsData);
    }
  }, [lng]);

  return (
    <div className="home_slider_container">
      {[...data, ...tagsData]
        ?.sort((a: { priority: number }, b: { priority: number }) => b?.priority - a?.priority)
        ?.map((m) => {
          return (
            <HomePageGamesSlider
              key={m.tagName}
              category={m.category || 'none'}
              tagName={m.tagName}
              trTagName={m?.trTagName}
              template={m.template}
              limit={m.limit}
              showViewAllLink={m.category !== null}
            />
          );
        })}
    </div>
  );
};
export default HomePageGamesSliders;
