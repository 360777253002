import { FC } from 'react';
import PopUpContainer from 'src/components/common/modal/PopUp';
import './styles.scss';

interface IProps {
  data: any;
  close: () => void;
}
const PromotionModal: FC<IProps> = ({ data, close }) => {
  return (
    <PopUpContainer isOpen handleClose={close} hasOverlay type="fullSize">
      <iframe src={data.iframeSrc} className="promo_iframe" />
    </PopUpContainer>
  );
};

export default PromotionModal;
