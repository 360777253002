export type gamesState = {
  tags: any[] | null;
  selectedTag: string;
  selectedProvider: string[];
  game: any;
  gamesTotalCount: number;
};

export const GAMES_SET_TAGS = 'games/GAMES_SET_TAGS';

export const GAMES_SET_SELECTED_TAG = 'games/GAMES_SET_SELECTED_TAG';

export const GAMES_SET_SELECTED_PROVIDER = 'games/GAMES_SET_SELECTED_PROVIDER';

export const GAMES_SET_SELECTED_GAME = 'games/GAMES_SET_SELECTED_GAME';

export const GAMES_SET_GAMES_TOTAL_COUNT = 'games/GAMES_SET_GAMES_TOTAL_COUNT';
