import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from 'src/components/common/Typography';
import { EnumRoutePaths } from 'src/configs/routes';
import { allTagChecker, detectDevice, scrollToTop, setBodyOverflow } from 'src/helpers/utils';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useBannerActions } from 'src/hooks/useBannerActions';
import { CMSManager } from 'src/manager/CMSManager';
import { getBonuses } from 'src/services/dataApi';
import { RootState } from 'src/types/store-types';
import CardWithInfoPage from './CardWithInfoPage/CardWithInfoPage';
import PromotionInfoPage from './PromotionInfoPage/PromotionInfoPage';
import PromotionModal from './PromotionModal';
import './promotions.scss';

const StaticPromotionPage: FC = () => {
  const { t }: Translation = useTranslation();
  const { locale } = useI18n();

  const device = detectDevice();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const { providerAction, loginAction, registrAction, promotionAction, gameAction, categoryAction, externalUrlAction } =
    useBannerActions();

  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { categoriesData } = useSelector((state: RootState) => state.configs);
  const { tags } = useSelector((state: RootState) => state.games);

  const [tagList, setTagList] = useState<any[]>([]);

  const [bonuses, setBonuses] = useState<any[] | null>(null);
  const [bonus, setBonus] = useState<Bonus | null>(null);
  const [selectedTag, setSelectedTag] = useState<string>('all');
  const [showPromoModal, setShowPromoModal] = useState<boolean>(false);

  const promotionTags = useMemo(() => {
    if (!bonuses) return null;

    const targetArray = bonuses.reduce((acc: string[], cur: any) => {
      if (cur?.promoTags[0]) {
        acc.push(cur?.promoTags[0]);
      }
      return acc;
    }, []);

    return allTagChecker(Array.from(new Set(targetArray)));
  }, [bonuses]);

  const filteredPromotionContent = useMemo(() => {
    if (!bonuses) return null;

    const sortedBonuses = bonuses.sort((a, b) => b?.priority - a?.priority);

    if (!promotionTags || promotionTags.length <= 0 || selectedTag === 'all') return sortedBonuses;

    return sortedBonuses.filter(({ promoTags }: any) => promoTags?.some((m: string) => m === selectedTag));
  }, [bonuses, selectedTag, promotionTags]);

  const handleClick = (bonus: any): void => {
    if (process.env.REACT_APP_WEBSITENAME === 'PapiGames') {
      if (bonus?.iframeSrc) {
        window.open(bonus.iframeSrc);
      } else if (bonus?.attributes?.iframeSrc) {
        window.open(bonus?.attributes?.iframeSrc);
      } else {
        navigate(`${pathname}?promo=${bonus.id}`);
      }
    } else {
      if (bonus?.iframeSrc) {
        setShowPromoModal(true);
        setBodyOverflow('unset');
      }

      navigate(`${pathname}?promo=${bonus.id}`);
    }
  };

  const closePromoModal = (): void => {
    setBodyOverflow('set');
    setShowPromoModal(false);
    navigate(EnumRoutePaths.PROMOTIONS);
    setBonus(null);
  };

  const handleBack = (): void => {
    navigate(pathname);
    setBonus(null);
  };

  const handleSelectetTag = (tag: string): void => {
    setSelectedTag(tag);
  };

  const redirect = (attributes: any) => () => {
    scrollToTop();

    switch (attributes?.target) {
      case 'goToRegister':
        registrAction();
        break;
      case 'goToLogin':
        loginAction();
        break;
      case 'goToPromotion':
        promotionAction(attributes?.targetParam);
        break;
      case 'goToProvider':
        providerAction({ provider: attributes?.targetParam, tags: tags, tagList: tagList });
        break;
      case 'goToExternalUrl':
        externalUrlAction();
        break;
      case 'goToGame':
        gameAction(attributes?.targetParam);
        break;
      case 'goToCategory':
        categoryAction(attributes?.targetParam);
        break;
      case 'goToRace':
      case 'noAction':
        break;
      default:
        return;
    }
  };

  const getBonusData = (): void => {
    const _deviceTtype = device === 'tablet' ? 'desktop' : device;

    getBonuses(_deviceTtype, locale).then((res) => {
      if (res?.data) {
        const _ = CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string]?.getPromotionData(res.data);
        setBonuses(_);
      }
    });
  };

  useEffect(() => {
    scrollToTop();
  }, [bonus]);

  useEffect(() => {
    getBonusData();
  }, [device, locale]);

  useEffect(() => {
    categoriesData &&
      setTagList(categoriesData[selectedCategory]?.tags?.filter((f) => f.tagName === 'allGames')?.[0].tagGames || []);
  }, [categoriesData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const promoId = Number(queryParams.get('promo'));

    if (promoId && !!bonuses?.length) {
      const promo = bonuses?.filter((f) => f.id === promoId)[0];
      promo ? setBonus(promo || null) : navigate(pathname);

      if (promo?.iframeSrc) {
        setShowPromoModal(true);
        setBodyOverflow('unset');
      }
    }
  }, [bonuses, new URLSearchParams(search)]);

  if (!filteredPromotionContent) return null;

  return (
    <div className="promotions__container">
      {!bonus && (
        <div className="promotions__inner">
          <Typography className="promotions__title" variant={'h6'}>
            {t('promotions')}
          </Typography>
          {promotionTags && promotionTags?.length > 0 && (
            <div className="promotions__tags">
              {promotionTags.map((tag: string) => (
                <div
                  key={tag}
                  onClick={handleSelectetTag.bind(null, tag)}
                  className={`promotions__tag ${tag === selectedTag ? 'promotions__tag--active' : ''}`}
                >
                  {t(tag) ?? tag}
                </div>
              ))}
            </div>
          )}
          <div className="promotions__bonus-list">
            {filteredPromotionContent?.map((cardItem: Bonus) => (
              <CardWithInfoPage key={cardItem.id} cardInfo={cardItem} onBtnClick={handleClick} />
            ))}
          </div>
        </div>
      )}
      {showPromoModal && <PromotionModal data={bonus} close={closePromoModal} />}
      {bonus && !showPromoModal && (
        <PromotionInfoPage handleBack={handleBack} handleRedirect={redirect} promotionPageInfo={bonus} />
      )}
    </div>
  );
};
export default StaticPromotionPage;
