import cn from 'classnames';
import { FC, useMemo, useRef, useState } from 'react';
import { Button } from 'src/components/common/Button';
import GameCard from 'src/components/common/GameCard';
import { TEMPLATE_1_SLIDER_GAMES_COUNT } from 'src/utils/constants';
import '../styles.scss';
import { LeftArrow, RightArrow } from './Arrows';

type Props = {
  activeGames: any;
  styles: any;
  viewAllGamesOfTag: any;
  t: any;
  tagName: string;
  isMobileOnly: boolean;
  showArrows: boolean | undefined;
  index: number;
  onNextClick: () => void;
  onPrevClick: () => void;
  showViewAllLink: boolean;
};
const Template1: FC<Props> = ({
  activeGames,
  styles,
  viewAllGamesOfTag,
  tagName,
  t,
  isMobileOnly,
  showArrows,
  showViewAllLink,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [left, setLeft] = useState<number>(TEMPLATE_1_SLIDER_GAMES_COUNT);

  //TO DO ADD 161 AS SKIN VAR
  const onPrev = (): void => {
    setLeft((prev) => prev - TEMPLATE_1_SLIDER_GAMES_COUNT);

    (ref as any).current.scrollTo({
      left: (ref as any)?.current?.scrollLeft - TEMPLATE_1_SLIDER_GAMES_COUNT * 161,
      behavior: 'smooth',
    });
  };

  const onNext = (): void => {
    setLeft((prev) => prev + TEMPLATE_1_SLIDER_GAMES_COUNT);

    (ref as any).current.scrollTo({
      left: (ref as any)?.current?.scrollLeft + TEMPLATE_1_SLIDER_GAMES_COUNT * 161,
      behavior: 'smooth',
    });
  };

  const viewAll = useMemo(() => {
    return () => {
      if (showViewAllLink) {
        viewAllGamesOfTag();
      }
    };
  }, [showViewAllLink, viewAllGamesOfTag]);

  return (
    <div className={cn('game_container', 'template_1')} style={styles}>
      <div className="game_container_header" style={styles}>
        <div className="game_container_header_title_with_arrows">
          <Button ariaLabelledby="arrow" type="button" onClick={viewAll} className="tag_title">
            {t(tagName)}
          </Button>
          {(!isMobileOnly || showArrows) && activeGames?.length > 1 && (
            <div className="arrows">
              <LeftArrow
                disabled={left === TEMPLATE_1_SLIDER_GAMES_COUNT}
                placement="center"
                type="squaredDark"
                slide={onPrev}
              />
              <RightArrow disabled={activeGames.length < left} placement="center" type="squaredDark" slide={onNext} />
            </div>
          )}
        </div>
        {showViewAllLink && (
          <Button type="button" onClick={viewAllGamesOfTag} className="viewAll" justifyContentValue="flex-end">
            {t('viewAll')}
          </Button>
        )}
      </div>
      <div className="game_carousel_container">
        <div className="inner_carousel">
          <div className="slider_template_1" ref={ref}>
            <div className="first_col">
              <GameCard
                data={activeGames[0]}
                showDetails={false}
                size={'lg'}
                effects={{
                  hoverType: 'fullWidthImage',
                  src: '',
                  text: '',
                }}
              />
            </div>
            <div className="second_col">
              {activeGames.slice(1)?.map((carouselItemArr: any, i: number) => {
                return (
                  <GameCard
                    key={carouselItemArr.name + i}
                    data={carouselItemArr}
                    showDetails={false}
                    size={'md_m'}
                    effects={{
                      hoverType: 'fullWidthImage',
                      src: '',
                      text: '',
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template1;
