import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import GameCard from 'src/components/common/GameCard';
import { Typography } from 'src/components/common/Typography';
import '../styles.scss';

type Props = {
  activeGames: any;
  styles: any;
  isMobileOnly: boolean;
};
const Template4: FC<Props> = ({ activeGames, styles, isMobileOnly }) => {
  const { t }: Translation = useTranslation();

  return (
    <div className={cn('game_container', 'template_4')} style={styles}>
      {isMobileOnly && (
        <div className="template_4_header">
          <Typography variant="h4">{t('top_games')}</Typography>
        </div>
      )}
      <div className="template_4_content">
        {!!activeGames &&
          activeGames?.map((carouselItemArr: any, i: number) => {
            return (
              <GameCard
                key={carouselItemArr.name + i}
                data={carouselItemArr}
                showDetails={false}
                size={isMobileOnly ? 'sm' : 'md_l'}
                useRatio
                template={isMobileOnly ? 'template_2' : 'template_4'}
                effects={{
                  hoverType: '',
                  src: '',
                  text: '',
                }}
              />
            );
          })}
      </div>
    </div>
  );
};
export default Template4;
