import { FC, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSkinId, setBodyOverflow, setParams } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { gameUrlRequest } from 'src/services/gameApi';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { setGameBoxWidgetClose, setSelectedCategory } from 'src/store/app/actions';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { setSelectedGame } from 'src/store/games/actions';
import {
  forceCloseGameBox,
  setActualMissionsCount,
  setFreeSpinGames,
  setSelectedTounamentGame,
} from 'src/store/integrations/actions';
import { setBalance } from 'src/store/user/actions';
import { GameParamType } from 'src/types/req-dto/game';
import { RootState } from 'src/types/store-types';

const GameBox: FC = () => {
  const navigate = useNavigate();
  const { locale } = useI18n();
  const { t } = useTranslation();
  const skinId = getSkinId();

  const _freeSpinGamesReq = useConnectedAction(setFreeSpinGames.req);
  const _forceCloseGameBox = useConnectedAction(forceCloseGameBox);
  const _setGameBoxWidgetClose = useConnectedAction(setGameBoxWidgetClose);
  const _selectedGame = useConnectedAction(setSelectedGame);
  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _setSelectedTounamentGame = useConnectedAction(setSelectedTounamentGame);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _setActualMissionsCount = useConnectedAction(setActualMissionsCount);
  const _requestBalance = useConnectedAction(setBalance.req);
  const _openDialog = useConnectedAction(openDialog);

  const { user } = useSelector((state: RootState) => state.user);
  const { selectedTag, game } = useSelector((state: RootState) => state.games);
  const { forceCloseGameBoxState } = useSelector((state: RootState) => state.integrations);
  const { selectedCategory, tournamentIsOpen, isGameBoxOpen } = useSelector((state: RootState) => state.app);

  const iframe = (document?.getElementById('gamebox-widget') as HTMLIFrameElement)?.contentWindow;
  const widgetTopPosition = isMobile ? '50px' : '70px';

  const [iframeSrc, setIframeSrc] = useState<string | null>(null);
  const [iframeHeight, setIframeHeight] = useState<string>('auto');
  const [iframeWidth, setIframeWidth] = useState<string>(isMobile ? '100%' : 'auto');
  const [iframeTopPosition, setIframeTopPosition] = useState<string>(widgetTopPosition);

  const styles: any = useMemo(() => {
    return {
      width: iframeWidth,
      height: iframeHeight,
      top: iframeTopPosition,
      position: tournamentIsOpen ? 'initial' : 'fixed',
      border: 'none',
      right: '0',
      zIndex: '150',
      transition: 'height 0.2s',
    };
  }, [iframeWidth, iframeHeight, iframeTopPosition, tournamentIsOpen, isGameBoxOpen, selectedCategory, game]);

  const closeGameBoxByGameInitiative = (): void => {
    setIframeHeight('0');
    setIframeWidth('0');
    setIframeTopPosition(widgetTopPosition);
    _requestBalance();

    selectedTag === 'free_rounds' && user && _freeSpinGamesReq();
  };

  const getGameUrl = async (gameId: number, walletId: string): Promise<any> => {
    const params: GameParamType = setParams(user, { id: gameId }, skinId, locale, isMobile, walletId);

    try {
      return await gameUrlRequest(params);
    } catch (e: any) {
      const msg = t(`game_error.${e?.message === 'Network Error' ? 'network_error' : 'unavailable'}`, { gameId });

      if (iframe) {
        iframe?.postMessage(
          {
            type: 'GAME_OPEN_FAILURE',
            gameId,
            msg,
          },
          '*'
        );
      }

      if (!e?.response?.data.success) {
        _forceCloseGameBox(true);
      }

      closeGameBoxByGameInitiative();
    }
  };

  const getGameUrlStr = (): void => {
    getGameUrl(+game?.id, game?.gameWalletId).then((res) => {
      if (res?.success && user && locale) {
        const gameResponse = res.result.data;
        if (iframe) {
          if (isMobile) {
            setBodyOverflow('unset');
          }
          iframe?.postMessage(
            {
              type: 'SET_GAME_URL',
              gameUrl: gameResponse.url,
              gameInfo: game,
            },
            '*'
          );
        } else {
          const el = (document?.getElementById('gamebox-widget') as HTMLIFrameElement)?.contentWindow;
          el?.postMessage(
            {
              type: 'SET_GAME_URL',
              gameUrl: gameResponse.url,
              gameInfo: game,
            },
            '*'
          );
        }
      } else {
        _openDialog({
          dialogType: EnumDialogsKeys.GAME_URL_FAILURE,
          dialogProps: { data: 'gameUrlFailure' },
        });
        iframe?.postMessage(
          {
            type: 'GAME_URL_CRASH',
            msg: '',
          },
          '*'
        );
      }
      setIframeHeight('100%');
      setIframeWidth('100%');
      setIframeTopPosition('0');
    });
  };

  const gameBoxMessageHandler = (event: any): void => {
    switch (event.data.type) {
      case 'CHANGE_GAME_BOX_SIZE':
        const { height, width, top } = event.data;
        setIframeHeight(height ?? '0px');
        setIframeWidth(width ?? '0px');
        setIframeTopPosition(top ?? '0px');
        break;
      case 'CLOSE_GAME_BOX':
        document.body.removeAttribute('style');
        if (isMobile) {
          setBodyOverflow('set');
        }
        _selectedGame(null);
        closeGameBoxByGameInitiative();
        break;
      case 'CLOSE_GAME_BOX_GLOBAL_WIDGET':
        _setGameBoxWidgetClose();
        isMobile && closeGameBoxByGameInitiative();
        break;
      case 'CHANGE_GAME_BOX_NOTIFICATION_COUNT':
        event.data.count && _setActualMissionsCount(event.data.count);
        return;
      case 'OPEN_TOURNAMENT_MODULE_GAME':
        navigate('/category/casinoTournament');
        _setSelectedTounamentGame(event.data.data);
        _setSelectedCategory('casinoTournament');
        break;
      case 'OPEN_FREEROUNDS_PAGE':
        navigate('/category/casino');
        _setSelectedCategory('casino');
        break;
      case 'OPEN_DEPOSIT_MODAL':
        _openAccountModal({ section: 'deposit', subSection: 'deposit' });
        break;
      case 'CHANGE_CATEGORY':
        if (event.data.params && !isNaN(event.data.params)) {
          _setSelectedCategory(event.data.category);
          localStorage.setItem('missionId', event.data.params);
          navigate(`/category/${event.data.category}`);
        } else if (event.data.category) {
          _setSelectedCategory(event.data.category);
          navigate(`/category/${event.data.category}`);
        }
        break;
      case 'FUN_BONUS_SET_GAME_URL':
        iframe?.postMessage(
          {
            type: 'CHANGE_WIDGET_VIEW',
            view: 'game',
          },
          '*'
        );
        iframe?.postMessage(
          {
            type: 'SET_GAME_URL',
            gameUrl: event.data?.gameUrl,
            gameInfo: event.data?.gameInfo,
          },
          '*'
        );
        setIframeHeight('100%');
        setIframeWidth('100%');
        setIframeTopPosition('0');
        break;
      case 'FUN_BONUS_GAME_OPEN_FAILURE':
        iframe?.postMessage(
          {
            type: 'GAME_OPEN_FAILURE',
            gameId: event.data?.gameId,
            msg: event.data?.msg,
          },
          '*'
        );
        closeGameBoxByGameInitiative();
        break;
      default:
        break;
    }

    const gameCloseMessagesArray = ['GAME_MODE:CLOSED'];

    if (gameCloseMessagesArray.includes(event.data)) {
      closeGameBoxByGameInitiative();
    }
  };

  useEffect(() => {
    if (user?.token && user) {
      setIframeSrc(
        // eslint-disable-next-line max-len
        `${process.env.REACT_APP_GAME_BOX_MODULE}?skinId=${skinId}&userId=${user.id}&username=${
          user.username
        }&playerToken=${user.token}&websiteLang=${locale || process.env.REACT_APP_FALLBACK_LANGUAGE}&websiteName=${
          process.env.REACT_APP_WEBSITENAME
        }&widgetTopPosition=${widgetTopPosition}&gameFullScreenMode=true${
          process.env.REACT_APP_FORMATTED_AMOUNT_WITH_DOT ? `&formattedAmountWithDot=true` : ''
        }&logo=${window.location.origin}/images/logo.svg`
      );
    } else {
      console.log('PLAYER not logined');
    }
  }, [user]);

  useEffect(() => {
    if (!!iframe) {
      iframe?.postMessage(
        {
          type: 'CHANGE_WIDGET_VIEW',
          view: 'toaster',
        },
        '*'
      );
    }
  }, []);

  useEffect(() => {
    if (!!iframe && locale) {
      iframe?.postMessage(
        {
          type: 'GAME_BOX_CHANGE_LANGUAGE',
          language: locale,
        },
        '*'
      );
    }
  }, [locale]);

  useEffect(() => {
    if (forceCloseGameBoxState) {
      closeGameBoxByGameInitiative();
      _forceCloseGameBox(false);
    }
  }, [forceCloseGameBoxState]);

  useEffect(() => {
    if (game) {
      iframe?.postMessage(
        {
          type: 'CHANGE_WIDGET_VIEW',
          view: 'game',
        },
        '*'
      );
      getGameUrlStr();
    }
  }, [user, game]);

  useEffect(() => {
    window.addEventListener('message', gameBoxMessageHandler);
    return () => {
      window.removeEventListener('message', gameBoxMessageHandler);
    };
  }, [iframe]);

  if (!user) return null;

  return <iframe title="gamebox" src={iframeSrc as string} id="gamebox-widget" allowFullScreen style={styles} />;
};

export default GameBox;
