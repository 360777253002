import { CountdownProps, Statistic } from 'antd';
import cn from 'classnames';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'src/components/common/Checkbox';
import SuccessfullyReged from 'src/components/common/SuccessfullyReged';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { makeSignInPayload, makeSignUpPayload } from 'src/helpers/transformers';
import { getField } from 'src/helpers/utils';
import { payloadIsValid } from 'src/helpers/validationScheme';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useEnterSubmit } from 'src/hooks/useEnterSubmit';
import { CMSManager } from 'src/manager/CMSManager';
import { senOTPRequestAgain, signUpRequest, validateOTPRequest } from 'src/services/authorizationApi';
import { getStaticPagesFromCms } from 'src/services/dataApi';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { setSelectedCategory } from 'src/store/app/actions';
import { setBottomNavigation } from 'src/store/configs/actions';
import { setCPF_User, setError, signIn as signInAction, toggleActivateBtn } from 'src/store/user/actions';
import { ISignUpRes } from 'src/types/res-dto/authorization';
import { RootState } from 'src/types/store-types';
import { Button } from '../../../../../common/Button';
import { Input } from '../../../../../common/Input';
import PopUpContainer from '../../../../../common/modal/PopUp';
import SignupForm from './SignupForm';
import './styles.scss';

interface IProps extends ISignUpRes {
  close: () => void;
  setContent: (action: 'open' | 'close', content: 'login' | 'registration' | 'forgot' | null) => void;
}

const SignUpContent: FC<IProps> = ({
  setContent,
  registrationSteps,
  close,
  forgotLink,
  signIn,
  styles,
  showPreviousBtn,
}) => {
  const { t }: Translation = useTranslation();
  const { locale } = useI18n();
  const { Countdown } = Statistic;
  const _setBottomNavigation = useConnectedAction(setBottomNavigation);
  const { bottomNavigation } = useSelector((state: RootState) => state.configs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const formRef = useRef<HTMLDivElement | null>(null);

  const queryParams = new URLSearchParams(search);
  let affiliateId: any = '';
  if (queryParams.get('affiliateId')) {
    affiliateId = queryParams.get('affiliateId');
  } else if (sessionStorage.getItem('affiliateParams')) {
    affiliateId = JSON.parse(sessionStorage.getItem('affiliateParams') as any)?.id;
  }

  const _toggleActivateBtn = useConnectedAction(toggleActivateBtn);
  const _signInReq = useConnectedAction(signInAction.req);
  const _setCPF_User = useConnectedAction(setCPF_User);
  const _setError = useConnectedAction(setError);
  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const startTime = Date.now();
  const { errors: signUpErrors, loading, activateButton } = useSelector((state: RootState) => state.user);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const [activeStep, setActiveStep] = useState<number>(1);
  const [successfullyReged, setSuccessfullyReged] = useState<boolean>(false);
  const [checkEmail, setCheckEmail] = useState<boolean>(false);
  const [localErrorsState, setLocaleErrorsState] = useState<{ [key: string]: string } | null>(null);
  const [formValues, setFormValues] = useState<any>([]);
  const [termsAndConditionsCheckbox, setTermsAndConditionsCheckbox] = useState<boolean>(false);
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [signUpRequestLoading, setSignUpRequestLoading] = useState<boolean>(false);
  const [openOtpPopUp, setOpenOtpPopUp] = useState<boolean>(false);
  const [otpValue, setOtpValue] = useState<string>('');
  const [otpError, setOtpError] = useState<string>('');
  const [isBtnDisable, setIsBtnDisable] = useState<boolean>(false);
  const [playerRegOtpData, setPlayerRegOtpData] = useState<Record<string, string>>({});

  const classes = `${cn('primaryButton', 'success-filled-btn', { ['disabled']: signUpRequestLoading })}`;

  const openInfoAndRulesModal = (): void => {
    _openAccountModal({ section: 'info_and_rules', subSection: 'Terms and Conditions', fullHeight: true });
  };

  const getStaticPageData = (): void => {
    if (bottomNavigation.length === 0) {
      getStaticPagesFromCms(locale).then((res) => {
        if (res?.data?.[0]) {
          const _ = CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string].getInfoAndRulesData(res.data);
          _setBottomNavigation(_);
        } else {
          console.error('bottom navigation from csm is not found');
        }
      });
    }
  };

  useEffect(() => {
    getStaticPageData();
  }, [locale]);

  const changeContent = (content: 'forgot' | 'login') => (): void => {
    setContent('open', content);
  };

  const handleNavigateInStepper = (step: number, action?: string): void => {
    const field = getField(formValues, 'CPF');
    if (field && !Boolean(field['CPF']?.value)) {
      setLocaleErrorsState({ CPF: 'requiredField' });
    } else if (!Object.keys(signUpErrors).length) {
      setActiveStep(step);
    } else if (action === 'goBack') {
      _setError({});
      setActiveStep(step);
    }

    formRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSubmit = (): void => {
    handleSetErrors();

    const payload: any = makeSignUpPayload(formValues, affiliateId || '');

    if (payloadIsValid(payload, requiredFields, setLocaleErrorsState, t) && !Object.keys(signUpErrors).length) {
      setSignUpRequestLoading(true);
      signUpRequest(payload)
        .then((res: any) => {
          if (res?.message === 'verify_email') {
            setCheckEmail(true);
          } else if (res?.pendingOtpVerification) {
            const endTime = Date.now() + res?.otpExpiresIn;
            setPlayerRegOtpData({
              endTime,
              resendOtpAfter: res?.resendOtpAfter,
              requestIdentifier: res?.requestIdentifier,
            });
            setIsBtnDisable(true);
            setOpenOtpPopUp(true);
          } else if (res?.success) {
            setSuccessfullyReged(true);
          }
        })
        .finally(() => {
          setSignUpRequestLoading(false);
        });
    }
  };

  const closeSuccessfullyRegedModal = (): void => {
    const payload: any = makeSignUpPayload(formValues, affiliateId || '');

    _signInReq({
      data: makeSignInPayload({
        username: payload.username,
        password: payload.password,
      }),
      onSuccessCb: () => {
        if (generalConfigs?.redirectToSport) {
          navigate('/category/betting');
          _setSelectedCategory('betting');
        }
      },
      dispatch,
    });

    setTimeout(() => close(), 1000);
  };

  const handleCheckErrors = useCallback(() => {
    if (localErrorsState) {
      setFormValues((prevState: any) => {
        const newState = [...prevState];

        newState.forEach((obj: any, index: number) => {
          Object.keys(localErrorsState).forEach((key: string) => {
            if (obj.hasOwnProperty(key)) {
              newState[index][key].error = localErrorsState[key];
            }
          });
        });

        return newState;
      });
    }
  }, [localErrorsState, formValues]);

  const handleSetErrors = useCallback(() => {
    setFormValues((prevState: any) => {
      const newState = [...prevState];
      const fieldsWithOwnErrors = ['username', 'password', 'password2', 'phoneNumber', 'email'];

      newState.forEach((obj: any, index: number) => {
        Object.keys(obj).forEach((key: string): any => {
          if (obj[key].required && !Boolean(obj[key].value) && !fieldsWithOwnErrors.includes(key)) {
            newState[index][key].error = setLocaleErrorsState((prev: any) => ({
              ...prev,
              [key]: `${key}_field_required`,
            }));

            return null;
          }

          if (key === 'password2' && obj[key]?.value !== obj['password']?.value) {
            setLocaleErrorsState({
              ...localErrorsState,
              password2: t('password_must_be_a_match'),
            });

            return null;
          }
        });
      });

      return newState;
    });
  }, [formValues, termsAndConditionsCheckbox]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index: number): void => {
      const { name, value } = e.target;

      setLocaleErrorsState(null);
      setFormValues((prevState: any) => {
        const newState = [...prevState];
        if (newState[index][name]) {
          newState[index][name].value = value;
          newState[index][name].error = null;
        }

        if (name === 'password') {
          newState[index]['password2'].error = null;
        }

        if (name === 'password2') {
          newState[index]['password'].error = null;
        }

        return newState;
      });
    },
    [formValues]
  );

  const disableHandleSubmit = useMemo(() => {
    let flag = false;

    if (formValues?.[activeStep - 1]) {
      const stepInputs = Object.values(formValues[activeStep - 1]);
      flag = stepInputs.map((m: any) => m.required && m.value?.length === 0).includes(true);
    }

    if (activeStep === registrationSteps?.length && !termsAndConditionsCheckbox) {
      flag = true;
    }

    if (Object.keys(signUpErrors).length) flag = true;

    if (!activateButton && formValues?.[activeStep - 1]?.cpf) {
      flag = true;
    }

    return flag;
  }, [formValues, registrationSteps, termsAndConditionsCheckbox, activeStep, signUpErrors, activateButton]);

  const renderSteperNav = useCallback(() => {
    const disabled = !Boolean(formValues.length) || loading;

    return (
      <div className="buttons_container">
        {showPreviousBtn && activeStep > 1 && (
          <Button onClick={() => handleNavigateInStepper(activeStep - 1)} disabled={disabled}>
            {t('button_previous')}
          </Button>
        )}
        {activeStep < Number(registrationSteps?.length) && (
          <Button
            className={classes}
            onClick={() => handleNavigateInStepper(activeStep + 1)}
            disabled={disableHandleSubmit}
          >
            {t('button_next')}
          </Button>
        )}
        {activeStep === Number(registrationSteps?.length) && (
          <>
            <div>
              <Checkbox
                name="terms_and_conditions_checkbox"
                value={termsAndConditionsCheckbox}
                onChange={() => setTermsAndConditionsCheckbox((prev) => !prev)}
                id="terms_and_conditions_checkbox"
                label={termsAndConditionsCheckboxText}
              />
            </div>
            <Button
              className={classes}
              disabled={disableHandleSubmit}
              loading={signUpRequestLoading}
              onClick={handleSubmit}
              id="registration-submit-btn"
            >
              {t('register')}
            </Button>
          </>
        )}
      </div>
    );
  }, [
    registrationSteps,
    activeStep,
    signUpErrors,
    formValues,
    loading,
    activateButton,
    disableHandleSubmit,
    termsAndConditionsCheckbox,
    signUpRequestLoading,
  ]);

  const termsAndConditionsCheckboxText = useMemo(() => {
    return (
      <span>
        {t('termsConditionsLabel')}
        <span className="terms_and_conditions_checkbox__link" onClick={openInfoAndRulesModal}>
          {t('generalTermsConditions')}
        </span>
      </span>
    );
  }, []);

  const steps = useMemo(() => {
    return registrationSteps?.map((_, i) => i + 1);
  }, [registrationSteps]);

  const renderForm = (): JSX.Element => {
    if (checkEmail) {
      return (
        <SuccessfullyReged
          steps={steps}
          classes={classes}
          onSubmit={close}
          title={t('successRegTitle')}
          content={t('emailVerficationContent')}
        />
      );
    } else if (successfullyReged) {
      return (
        <SuccessfullyReged
          steps={steps}
          classes={classes}
          onSubmit={closeSuccessfullyRegedModal}
          title={t('successFullRegister')}
          content={t('reg_successfully')}
        />
      );
    } else {
      return (
        <SignupForm
          formValues={formValues}
          activeStep={activeStep}
          affiliateId={affiliateId}
          styles={styles}
          forgotLink={forgotLink}
          signIn={signIn}
          changeContent={changeContent}
          signUpErrors={signUpErrors}
          registrationSteps={registrationSteps}
          renderSteperNav={renderSteperNav}
          ref={formRef}
        />
      );
    }
  };

  useEffect(() => {
    const reqFields: any = [];
    const _ = registrationSteps
      ? registrationSteps?.map((stepData: any, index: number) => {
          const stepObjKeys = Object.keys(stepData);

          return stepObjKeys.reduce((acc: any, cur: string) => {
            if (stepData[cur].required) {
              reqFields.push(cur);
            }

            acc[cur] = {
              ...stepData[cur],
              value: ['password', 'string', 'select'].includes(stepData[cur].type) || stepData[cur].required ? '' : 0,
              onChange: (e: ChangeEvent<HTMLInputElement>) => handleChange(e, index),
              type: cur.includes('password') ? 'password' : stepData[cur].type,
            };

            return acc;
          }, {});
        })
      : [];

    setRequiredFields(reqFields);
    setFormValues(_);
  }, [registrationSteps]);

  useEffect(() => {
    handleCheckErrors();
  }, [localErrorsState]);

  useEffect(() => {
    if (Object.keys(signUpErrors).length) {
      _toggleActivateBtn(false);
    } else {
      !disableHandleSubmit && _toggleActivateBtn(true);
    }
  }, [disableHandleSubmit, signUpErrors]);

  useEffect(() => {
    _setError({});

    return () => {
      if ((registrationSteps?.[0] as any)?.cpf) {
        _setCPF_User(null);
      }

      _toggleActivateBtn(false);
    };
  }, []);

  useEffect(() => {
    // TO DO remove cpf check
    if (!Object.keys(signUpErrors).length && (steps?.length === 1 || !(registrationSteps?.[0] as any)?.cpf)) {
      _toggleActivateBtn(true);
    }
  }, [termsAndConditionsCheckbox, registrationSteps]);

  useEnterSubmit('registration-submit-btn');

  const validateOtpCode = (): void => {
    const payload = {
      otp: otpValue,
      requestIdentifier: playerRegOtpData.requestIdentifier,
    };
    validateOTPRequest(payload).then((res) => {
      if (res?.message === 'registration_complete') {
        setOpenOtpPopUp(false);
        setPlayerRegOtpData({});
        setSuccessfullyReged(true);
      }
    });
  };
  const changeOtpCode = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    if (value.length <= 6) {
      setOtpValue(value);
      setOtpError(value.length === 6 ? '' : t('otp_validation'));
    }
  };

  const closePopUp = (): void => {
    setOpenOtpPopUp(false);
  };

  const sendAgainCode = (): void => {
    const { requestIdentifier } = playerRegOtpData;
    const payload = {
      requestIdentifier,
    };
    senOTPRequestAgain(payload).then((res: any) => {
      if (res?.message === 'otp_updated') {
        const endTime = Date.now() + res?.otpExpiresIn;
        setPlayerRegOtpData({
          endTime,
          resendOtpAfter: res?.resendOtpAfter,
          requestIdentifier: res?.requestIdentifier,
        });
        setIsBtnDisable(true);
      }
    });
  };

  const onChange: CountdownProps['onChange'] = () => {
    const { resendOtpAfter } = playerRegOtpData;
    const currentTime = Date.now();
    const elapsedTime = currentTime - startTime;
    const elapsedMinutes = elapsedTime / +resendOtpAfter;
    if (elapsedMinutes >= 1) {
      setIsBtnDisable(false);
    }
  };

  return (
    <>
      {activeStep !== 1 && !successfullyReged && (
        <div className="back_icon" onClick={() => handleNavigateInStepper(activeStep - 1, 'goBack')}>
          <SvgIcon pointer src={icons.arrowDown} color="white" />
          <span>{t('back')}</span>
        </div>
      )}

      {renderForm()}
      {openOtpPopUp && process.env.REACT_APP_OTP && (
        <PopUpContainer
          hasOverlay
          title={t('validate_account')}
          confirmText={t('validate')}
          isOpen={openOtpPopUp}
          handleOK={validateOtpCode}
          handleClose={closePopUp}
          disabled={!otpValue || !!otpError}
          className="otp_popup"
        >
          <Input error={otpError} type="number" name="otpCode" onChange={changeOtpCode} value={otpValue} />
          <div className="otp_popup_countDown">
            <Countdown value={playerRegOtpData.endTime} format="mm:ss" onChange={onChange} />
            <Button className={classes} size="sm" onClick={sendAgainCode} disabled={isBtnDisable}>
              {t('resend')}
            </Button>
          </div>
        </PopUpContainer>
      )}
    </>
  );
};

export default SignUpContent;
