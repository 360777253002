import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { balanceRequest } from 'src/services/userApi';
import { RootState } from 'src/types/store-types';
import { autoSignInRequest, signInRequest } from '../../services/authorizationApi';
import { IReqPayloadStructure } from '../../types/store-types';
import { FIRST_DEPOSIT_TO_BONUS_SUCCESS } from '../bonus-messaging/types';
import { setBalance, setError, setLogout, signIn } from './actions';
import { APP_BALANCE_REQ, LOGOUT, USER_AUTO_SIGN_IN_REQ, USER_SIGN_IN_REQ } from './types';

export function* signInSaga({ payload }: any): any {
  const { onSuccessCb, onFailCb, data } = payload;

  try {
    yield put(signIn.load(true));
    const resp: IReqPayloadStructure = yield call(signInRequest, data);

    yield put(setError({}));

    if (resp.success) {
      const now = moment();
      const storedDate = now.add(resp.result.exp, 'seconds').valueOf();

      yield put(setBalance.success(resp.result?.wallets));

      if (resp.result?.token && resp.result?.id && resp.result?.username && (window as any)?.gd_action) {
        (window as any)?.gd_action('login', {
          token: resp.result?.token,
          userId: resp.result?.id,
          username: resp.result?.username,
        });
      }
      localStorage.setItem('wallets', JSON.stringify(resp.result?.wallets));
      localStorage.setItem('user', JSON.stringify({ ...resp.result }));
      localStorage.setItem('sessionId', Date.now().toString());
      localStorage.setItem('storedDate', String(storedDate));

      yield put(signIn.success({ ...resp.result, storedDate }));
      yield put(signIn.load(false));

      if (onSuccessCb) {
        onSuccessCb();
      }
    } else {
      yield put(setError({ error: 'signIn_error_text' }));
      yield put(signIn.load(false));

      if (onFailCb) {
        onFailCb();
      }
    }
  } catch (error: any) {
    if (onFailCb) {
      onFailCb();
    }
  }
}

export function* autoSignInSaga({ payload }: any): any {
  const { onSuccessCb, onFailCb, data } = payload;
  if (!payload?.data?.token) {
    return;
  }
  try {
    yield put(signIn.load(true));
    const resp: IReqPayloadStructure = yield call(autoSignInRequest, data);
    (window as any).autoCloseWebsite = true;
    if (resp.success) {
      const now = moment();
      const storedDate = now.add(resp.result.exp, 'seconds').valueOf();

      yield put(setBalance.success(resp.result?.wallets));

      if (resp.result?.token && resp.result?.id && resp.result?.username && (window as any)?.gd_action) {
        (window as any)?.gd_action('login', {
          token: resp.result?.token,
          userId: resp.result?.id,
          username: resp.result?.username,
        });
      }
      localStorage.setItem('user', JSON.stringify({ ...resp.result }));
      localStorage.setItem('wallets', JSON.stringify(resp.result?.wallets));
      localStorage.setItem('sessionId', Date.now().toString());
      localStorage.setItem('storedDate', String(storedDate));
      yield put(signIn.success({ ...resp.result, storedDate }));
      yield put(signIn.load(false));

      if (onSuccessCb) {
        onSuccessCb();
      }
    } else {
      yield put(setError({ error: 'signIn_error_text' }));
      yield put(signIn.load(false));

      if (onFailCb) {
        onFailCb();
      }
    }
  } catch (error: any) {
    if (onFailCb) {
      onFailCb();
    }
  }
}

export function* signOutSaga(d?: any): any {
  // Send postmessage for GoS
  const tournametIframe: any = document.getElementById('tournament');

  // Send postmessage for FunBonuss
  const funMissionIframe: any = document.getElementById('funMissionIframe');

  // close poker popup
  if ((window as any)?.externalPopupLink) {
    (window as any)?.externalPopupLink?.close();
  }

  if (funMissionIframe) {
    yield funMissionIframe.contentWindow.postMessage({ type: 'logout' }, '*');
  }

  if (tournametIframe) {
    yield tournametIframe.contentWindow.postMessage({ type: 'logout' }, '*');
  }
  if ((window as any)?.autoCloseWebsite) {
    (window as any).close();
  }
  yield localStorage.removeItem('user');
  yield localStorage.removeItem('token');
  yield localStorage.getItem('token-expire') && localStorage.removeItem('token-expire');
  yield localStorage.removeItem('wallets');
  yield localStorage.removeItem('resumeGameId');
  yield localStorage.removeItem('referralLinks');
  yield localStorage.removeItem('sessionId');

  if (d?.payload && d?.payload.onSuccessCb) {
    d.payload.onSuccessCb();
  }
}

function* getBalance(): any {
  try {
    const user = yield select((store: RootState) => store.user.user);
    if (user) {
      const response = yield call(balanceRequest);
      const { wallets } = response.result;

      yield put(setBalance.success(wallets));
    } else {
      console.log('Wrong getBalance => user not found');
    }
  } catch (e: any) {
    //TO DO show some popup for inform abour force logout
    if (e?.response?.data?.errorKey === 'multiLogin') {
      yield put(setLogout());
    }
    yield put(setBalance.fail());
  }
}

export function* watchAutoSignIn(): any {
  yield takeLatest(USER_AUTO_SIGN_IN_REQ, autoSignInSaga);
}

export function* watchUser(): any {
  yield takeLatest(USER_SIGN_IN_REQ, signInSaga);
  yield takeLatest(LOGOUT, signOutSaga);
  yield takeLatest(APP_BALANCE_REQ, getBalance);
  yield takeLatest(FIRST_DEPOSIT_TO_BONUS_SUCCESS, getBalance);
}
