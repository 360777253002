import React, { FC, useCallback } from 'react';
import { Components } from 'src/configs/components/components';
import '../styles.scss';

type Props = {
  settings: any;
};
const ContentPageConstructor: FC<Props> = ({ settings }) => {
  const renderContentPageWidgets = useCallback((): JSX.Element => {
    const orderIndex = {};
    Object.keys(settings).forEach((key: string) => (orderIndex[`${key}`] = settings[key].priority));

    const widgetLine = Object.keys(settings)
      .sort((a: string, b: string) => orderIndex[a] - orderIndex[b])
      .filter((key: string) => key !== 'styles')
      .map((key: string, index: number) => {
        return React.createElement(Components[key], {
          settings: settings[key],
          key: `${key}_${index}`,
        });
      });

    return <>{widgetLine}</>;
  }, []);

  return <div className="content_container">{renderContentPageWidgets()}</div>;
};
export default ContentPageConstructor;
