import cn from 'classnames';
import { FC, useRef, useState } from 'react';
import { Button } from 'src/components/common/Button';
import GameCard from 'src/components/common/GameCard';
import { GAME_CARD_SIZES, TEMPLATE_5_SLIDER_GAMES_COUNT } from 'src/utils/constants';
import '../styles.scss';
import { LeftArrow, RightArrow } from './Arrows';

type Props = {
  activeGames: any;
  styles: any;
  viewAllGamesOfTag: any;
  t: any;
  tagName: string;
  isMobileOnly: boolean;
  index: number;
  onNextClick: () => void;
  onPrevClick: () => void;
};
const Template5: FC<Props> = ({ activeGames, styles, viewAllGamesOfTag, tagName, t, isMobileOnly }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [left, setLeft] = useState<number>(TEMPLATE_5_SLIDER_GAMES_COUNT);

  //TO DO REMOVE THIS FUNCTIONS TO GENERAL PLACE
  const onPrev = (): void => {
    setLeft((prev) => prev - TEMPLATE_5_SLIDER_GAMES_COUNT);

    (ref as any).current.scrollTo({
      left: (ref as any)?.current?.scrollLeft - TEMPLATE_5_SLIDER_GAMES_COUNT * 161,
      behavior: 'smooth',
    });
  };

  const onNext = (): void => {
    setLeft((prev) => prev + TEMPLATE_5_SLIDER_GAMES_COUNT);

    (ref as any).current.scrollTo({
      left: (ref as any)?.current?.scrollLeft + TEMPLATE_5_SLIDER_GAMES_COUNT * 161,
      behavior: 'smooth',
    });
  };

  return (
    <div className={cn('game_container', 'template_5')} style={styles}>
      <div className="game_container_header" style={styles}>
        <div className="game_container_header_title_with_arrows">
          <Button ariaLabelledby="arrow" type="button" onClick={viewAllGamesOfTag} className="tag_title">
            {t(tagName)}
          </Button>
        </div>
        {activeGames.length > TEMPLATE_5_SLIDER_GAMES_COUNT && (
          <div className="game_btn_container">
            <Button type="button" onClick={viewAllGamesOfTag} className="viewAll success-outlined-btn">
              {t('viewAll')}
            </Button>
            {!isMobileOnly && activeGames?.length > TEMPLATE_5_SLIDER_GAMES_COUNT && (
              <div className="arrows">
                <LeftArrow
                  disabled={left === TEMPLATE_5_SLIDER_GAMES_COUNT}
                  placement="center"
                  type="squaredDark"
                  style="primary-filled-btn"
                  slide={onPrev}
                />
                <RightArrow
                  disabled={activeGames.length < left}
                  placement="center"
                  style="primary-filled-btn"
                  type="squaredDark"
                  slide={onNext}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className="template_3_carousel_conatiner"
        style={{ height: isMobileOnly ? 'auto' : GAME_CARD_SIZES['md_s'] + 20 }}
        ref={ref}
      >
        {activeGames?.map((game: any, i: number) => {
          return (
            <div id={game.id} key={game.id}>
              <GameCard
                key={game.name + i}
                data={game}
                showDetails={false}
                size={isMobileOnly ? 'sm' : 'md_s'}
                effects={{
                  hoverType: 'fullWidthImage',
                  src: '',
                  text: '',
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Template5;
