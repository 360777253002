import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { formatCurrency } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setBalance } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import { Button } from '../../common/Button';
import './styles.scss';

type Props = {
  settings: {
    needLogin: boolean;
    priority: number;
    styles: Styles;
    classes: string;
    type: string;
  };
};
const Balance: FC<Props> = ({ settings }) => {
  const { styles, classes, type } = settings;

  const _requestBalance = useConnectedAction(setBalance.req);

  const [balanceIsLoading, setBalanceIsLoading] = useState<boolean>(false);

  const { wallets, user } = useSelector((state: RootState) => state.user);
  const { showMainBalance } = useSelector((state: RootState) => state.app);

  const onRefresh = (): void => {
    setBalanceIsLoading(true);

    setTimeout(() => {
      setBalanceIsLoading(false);
    }, 200);

    _requestBalance();
  };

  useEffect(() => {
    user && _requestBalance();
  }, [user]);

  return (
    <div
      style={styles}
      className={cn(
        'balance_wrapper',
        {
          ['user_balance_loading']: balanceIsLoading,
        },
        classes
      )}
    >
      {type === 'withUserName' && <Typography variant={'h6'}>{user.nickname || user.username}</Typography>}
      <Button fontWeight="bold" variant="contained" color="transparent" onClick={onRefresh} icon={icons.refresh}>
        {type === 'withUserName' && 'Bakiye: '}
        {showMainBalance ? formatCurrency(wallets?.[0]?.balance, wallets?.[0].currency) : '*******'}
      </Button>
    </div>
  );
};
export default Balance;
