import { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SliderDataTransforamtionHelper } from 'src/helpers/SliderDataTransformationHelper';
import { scrollToTop } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setSelectedTag } from 'src/store/games/actions';
import { RootState } from 'src/types/store-types';
import Template1 from '../components/Template1';
import Template2 from '../components/Template2';
import Template3 from '../components/Template3';
import Template4 from '../components/Template4';
import Template5 from '../components/Template5';
import '../styles.scss';

type Props = {
  data: any[];
  tagName: string;
  template: string;
  showViewAllLink?: boolean;
  styles?: { [key: string]: string };
  showArrows?: boolean;
};
const GamesSlider: FC<Props> = ({ data, tagName, template, showViewAllLink = true, styles = {}, showArrows }) => {
  const { t }: Translation = useTranslation();
  const navigate = useNavigate();

  const _setSelectedTag = useConnectedAction(setSelectedTag);

  const { selectedCategory } = useSelector((state: RootState) => state.app);

  const [index, setIndex] = useState<number>(0);
  const [activeGames, setActiveGames] = useState<any[]>([]);

  const onPrevClick = (): void => {
    setIndex((prevState: number) => prevState - 1);
  };

  const onNextClick = (): void => {
    setIndex((prevState: number) => prevState + 1);
  };

  const viewAllGamesOfTag = (): void => {
    _setSelectedTag(tagName);
    navigate(`/category/${selectedCategory}`);

    scrollToTop();
  };

  const getTemplate = (): any => {
    if (isMobileOnly && template !== 'template_4') {
      return (
        <Template2
          activeGames={activeGames}
          styles={styles}
          viewAllGamesOfTag={viewAllGamesOfTag}
          t={t}
          tagName={tagName}
          isMobileOnly={isMobileOnly}
          showArrows={showArrows}
          index={index}
          onNextClick={onNextClick}
          onPrevClick={onPrevClick}
          showViewAllLink={showViewAllLink}
        />
      );
    } else {
      if (template === 'template_1')
        return (
          <Template1
            activeGames={data}
            styles={styles}
            viewAllGamesOfTag={viewAllGamesOfTag}
            t={t}
            tagName={tagName}
            isMobileOnly={isMobileOnly}
            showArrows={showArrows}
            index={index}
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
            showViewAllLink={showViewAllLink}
          />
        );

      if (template === 'template_2')
        return (
          <Template2
            activeGames={data}
            styles={styles}
            viewAllGamesOfTag={viewAllGamesOfTag}
            t={t}
            tagName={tagName}
            isMobileOnly={isMobileOnly}
            showArrows={showArrows}
            index={index}
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
            showViewAllLink={showViewAllLink}
          />
        );

      if (template === 'template_3')
        return (
          <Template3
            activeGames={activeGames}
            styles={styles}
            viewAllGamesOfTag={viewAllGamesOfTag}
            t={t}
            tagName={tagName}
            isMobileOnly={isMobileOnly}
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
            index={index}
          />
        );

      if (template === 'template_4')
        return <Template4 activeGames={activeGames} styles={styles} isMobileOnly={isMobileOnly} />;

      if (template === 'template_5')
        return (
          <Template5
            activeGames={activeGames}
            styles={styles}
            viewAllGamesOfTag={viewAllGamesOfTag}
            t={t}
            tagName={tagName}
            isMobileOnly={isMobileOnly}
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
            index={index}
          />
        );
    }
  };

  useEffect(() => {
    if (!!data?.length) {
      if (isMobileOnly && template !== 'template_4') {
        setActiveGames(data);
      } else {
        switch (template) {
          case 'template_1':
            setActiveGames(SliderDataTransforamtionHelper.setGameSliderTemplate1(data));
            break;
          case 'template_2':
            setActiveGames(SliderDataTransforamtionHelper.setGameSliderTemplate2(data, isMobileOnly));
            break;
          case 'template_3':
          case 'template_5':
            setActiveGames(SliderDataTransforamtionHelper.setGameSliderTemplate3(data));
            break;
          default:
            setActiveGames(data);
        }
      }
    }
  }, [data]);

  return getTemplate();
};
export default GamesSlider;
