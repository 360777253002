import { createAction } from 'deox';
import {
  GAMES_SET_GAMES_TOTAL_COUNT,
  GAMES_SET_SELECTED_GAME,
  GAMES_SET_SELECTED_PROVIDER,
  GAMES_SET_SELECTED_TAG,
  GAMES_SET_TAGS,
} from './types';

export const setTags = createAction(GAMES_SET_TAGS, (res) => (params) => res(params));

export const setSelectedTag = createAction(GAMES_SET_SELECTED_TAG, (res) => (params) => res(params));

export const setSelectedProvider = createAction(GAMES_SET_SELECTED_PROVIDER, (res) => (params) => res(params));

export const setSelectedGame = createAction(GAMES_SET_SELECTED_GAME, (res) => (params) => res(params));

export const setGamesTotalCount = createAction(GAMES_SET_GAMES_TOTAL_COUNT, (res) => (params) => res(params));
