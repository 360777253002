import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PopUpContainer from 'src/components/common/modal/PopUp';
import useActions from 'src/hooks/use-actions';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';

type Props = {
  data: any;
};
const ResumeTournament: FC<Props> = ({ data }) => {
  const { username, ...tournamentData } = data;

  const { t }: Translation = useTranslation();

  const actions = useActions();

  const _closeDialog = useConnectedAction(closeDialog);

  const onClose = useCallback((): void => {
    localStorage.setItem('resumeGameId', tournamentData.tournamentId);
    _closeDialog({ dialogType: EnumDialogsKeys.RESUME_TOURNAMENT });
  }, []);

  const onConfirm = useCallback((): void => {
    actions.gos();

    const casinoTournamentIframe: any = document.querySelector('iframe#tournament');

    if (casinoTournamentIframe) {
      casinoTournamentIframe?.contentWindow.postMessage({ type: 'openGame', game: data }, '*');
    }

    onClose();
  }, []);

  return (
    <PopUpContainer
      isOpen
      handleClose={onClose}
      hasOverlay
      handleOK={onConfirm}
      handleCancel={onClose}
      title={t(`${'resumeTournamentDialog'}.title`, data ?? {})}
      description={t(`${'resumeTournamentDialog'}.text`, data ?? {})}
    />
  );
};
export default ResumeTournament;
