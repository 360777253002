import { useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { RootState } from 'src/types/store-types';

const useActions = (isFunction?: boolean): any => {
  const navigate = useNavigate();

  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _openAuthModal = useConnectedAction(openAuthModal);

  const { user } = useSelector((state: RootState) => state.user);

  const loggedIn = !!user;

  const handleCategorySelection = useCallback((category: string) => {
    navigate(`/category/${category}`);
    _setSelectedCategory(category);
  }, []);

  const openRegisterView = useCallback(() => {
    setBodyOverflow('unset');
    _openAuthModal('registration');

    if (isMobileOnly) {
      document?.body?.requestFullscreen();
    }
  }, []);

  const onCallPromotionAction = (promotinId: string | number): void => {
    _setSelectedCategory('');
    navigate(`/promotions?promo=${promotinId}`);
  };

  const actionsList = {
    noAction: null,
    gos: handleCategorySelection.bind(null, 'casinoTournament'),
    goToCasino: handleCategorySelection.bind(null, 'casino'),
    goToRegister: loggedIn ? null : openRegisterView,
    goToPromotion: (promotinId: number) => onCallPromotionAction(promotinId),
  };

  const callAction = useCallback((action: string, ...args: any) => {
    if (actionsList[action]) actionsList[action](...args);
  }, []);

  return isFunction ? callAction : actionsList;
};

export default useActions;
