import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import PopUpContainer from 'src/components/common/modal/PopUp/Popup';
import { Typography } from 'src/components/common/Typography';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { firstDepositToBonus } from 'src/store/bonus-messaging/actions';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { readMessageRequest } from 'src/store/messages/actions';
import './index.scss';

interface IProps {
  data: any;
}

const FirstDepositToBonusWallet: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  const _readMessage = useConnectedAction(readMessageRequest);
  const _closeDialog = useConnectedAction(closeDialog);
  const _requestFirstDepositToBonus = useConnectedAction(firstDepositToBonus.req);

  const onConfirm = (): void => {
    _readMessage(data._id);
    _requestFirstDepositToBonus({ data: { bonusConfigId: data?.data?.bonusConfigId, isParticipating: true } });
    _closeDialog({ dialogType: EnumDialogsKeys.FIRST_DEPOSIT_TO_BONUS_WALLET });
    setBodyOverflow('set');
  };

  const onCancel = (): void => {
    _readMessage(data._id);
    _requestFirstDepositToBonus({ data: { bonusConfigId: data?.data?.bonusConfigId, isParticipating: false } });
    _closeDialog({ dialogType: EnumDialogsKeys.FIRST_DEPOSIT_TO_BONUS_WALLET });
  };

  return (
    <PopUpContainer
      handleClose={onCancel}
      hasOverlay={true}
      id="first-deposit-to-bonus"
      type="fullSize"
      isOpen
      title={t('firstDepositToBonusWalletDialog.title')}
    >
      <div className="first-deposit-to-bonus-container">
        <Typography className="first-deposit-to-bonus">{t('firstDepositToBonusWalletDialog.text')}</Typography>
        <div className="first-deposit-to-bonus--footer">
          <div className="btn-section">
            <Button className="primary-filled-btn first-deposit-to-bonus" type="button" onClick={() => onConfirm()}>
              {t('yes')}
            </Button>
            <Button className="primary-filled-btn first-deposit-to-bonus" type="button" onClick={() => onCancel()}>
              {t('No')}
            </Button>
          </div>
        </div>
      </div>
    </PopUpContainer>
  );
};

export default FirstDepositToBonusWallet;
