import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  onChange: (e: any) => void;
  required: boolean;
  data: string[];
  value: string;
};
const RadioInput: FC<Props> = ({ onChange, data, value, required }) => {
  const { t }: Translation = useTranslation();

  return (
    <div className="radio_buttons_wrapper">
      {data?.map((item: string) => (
        <label htmlFor={item} className="radio-label" key={item}>
          <input
            className="radio-input"
            type="radio"
            name={item}
            id={item}
            value={item}
            required={required}
            onChange={onChange}
            checked={item === value}
          />
          <span className="custom-radio" />
          {t(item)}
        </label>
      ))}
    </div>
  );
};

export default RadioInput;
