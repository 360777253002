import { FC } from 'react';
import { useSelector } from 'react-redux';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { RootState } from 'src/types/store-types';
import FinalTourInfo from './FinalTourInfo';
import FirstDepositToBonusWallet from './FirstDepositToBonusWallet';
import FreeSpins from './FreeSpins';
import GameLaunchPreview from './GameLaunchPreview';
import GameUrlFailure from './GameUrlFailure/GameUrlFailure';
import RegistrationFailure from './RegistrationFailure';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import ResumeTournament from './ResumeTournament';
import SpinAndGoStarted from './SpinAndGoStarted';
import SuccessFullyReged from './SuccessFullyReged';
import TournamentResults from './TournamentResults';
import TransactionInfo from './TransactionInfo';

const Dialogs: FC = () => {
  const dialogs = useSelector((state: RootState) => state.dialog.dialogs);

  return (
    <>
      {dialogs?.[EnumDialogsKeys.RESET_PASSWORD_SUCCESS] && (
        <ResetPasswordSuccess
          dialogCase={dialogs[EnumDialogsKeys.RESET_PASSWORD_SUCCESS].dialogCase}
          email={dialogs[EnumDialogsKeys.RESET_PASSWORD_SUCCESS].email}
        />
      )}
      {dialogs?.[EnumDialogsKeys.CHANGE_PASSWORD_SUCCESS] && (
        <ResetPasswordSuccess
          dialogCase={dialogs[EnumDialogsKeys.CHANGE_PASSWORD_SUCCESS].dialogCase}
          email={dialogs[EnumDialogsKeys.CHANGE_PASSWORD_SUCCESS].email}
          isChangePassword
        />
      )}
      {dialogs?.[EnumDialogsKeys.RESUME_TOURNAMENT] && (
        <ResumeTournament data={dialogs[EnumDialogsKeys.RESUME_TOURNAMENT]?.data} />
      )}
      {dialogs?.[EnumDialogsKeys.TOURNAMENT_RESULTS] && (
        <TournamentResults data={dialogs[EnumDialogsKeys.TOURNAMENT_RESULTS]?.data} />
      )}
      {dialogs?.[EnumDialogsKeys.FINAL_TOUR_INFO] && (
        <FinalTourInfo data={dialogs[EnumDialogsKeys.FINAL_TOUR_INFO]?.data} />
      )}
      {dialogs?.[EnumDialogsKeys.SECCESSFULLY_REGED] && <SuccessFullyReged />}
      {dialogs?.[EnumDialogsKeys.SPIN_AND_GO_STARTED] && (
        <SpinAndGoStarted data={dialogs[EnumDialogsKeys.SPIN_AND_GO_STARTED]?.data} />
      )}
      {dialogs?.[EnumDialogsKeys.REGISTRATION_FAILURE] && (
        <RegistrationFailure data={dialogs[EnumDialogsKeys.REGISTRATION_FAILURE]?.data} />
      )}
      {dialogs?.[EnumDialogsKeys.FREE_SPINS] && <FreeSpins data={dialogs[EnumDialogsKeys.FREE_SPINS]?.data} />}
      {dialogs?.[EnumDialogsKeys.GAME_LAUNCH_PREVIEW] && (
        <GameLaunchPreview data={dialogs[EnumDialogsKeys.GAME_LAUNCH_PREVIEW]?.data} />
      )}
      {dialogs?.[EnumDialogsKeys.FIRST_DEPOSIT_TO_BONUS_WALLET] && (
        <FirstDepositToBonusWallet data={dialogs[EnumDialogsKeys.FIRST_DEPOSIT_TO_BONUS_WALLET]?.data} />
      )}
      {dialogs?.[EnumDialogsKeys.MAIN_TO_POKER_TRANSACTION] && (
        <TransactionInfo data={dialogs[EnumDialogsKeys.MAIN_TO_POKER_TRANSACTION]?.data} />
      )}
      {dialogs?.[EnumDialogsKeys.GAME_URL_FAILURE] && (
        <GameUrlFailure data={dialogs[EnumDialogsKeys.GAME_URL_FAILURE]?.data} />
      )}
    </>
  );
};

export default Dialogs;
